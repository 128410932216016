.uploadImgContainer {
  border: 1px dashed #2459ad;
  @apply flex flex-col rounded-rd8px px-12px py-16px items-start
}

.uploadButton {
  border: 1px dashed #2459ad;
  padding: 4px 8px;
  @apply flex rounded-rd16px cursor-pointer items-center justify-center mt-16px self-center
}

.indexCol {
  width: 50px;
  @apply border-r border-gray-300 flex items-center justify-center py-2
}

.fileName {
  flex: 1;
  @apply pl-16px py-2 overflow-hidden
}

.row {
  @apply flex w-full border-t border-gray-300
}
