.containerModal {
  @apply flex bg-white rounded-rd8px overflow-hidden
}

.rightBanner {
  background: linear-gradient(180deg, #E9F3FF 0%, #FFFFFF 100%);
  @apply h-full w-277px flex flex-col relative
}

.successModal {
  width: calc(100vw - 24px);
  max-width: 375px;
  @apply bg-white rounded-rd8px lg:p-24px p-16px flex flex-col
}

@media (min-width: 1024px) {
  .successModal {
    min-width: 436px;
    @apply w-436px
  }
}
