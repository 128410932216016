@import "~@styles/variables.scss";
.modal {
  @apply flex items-center justify-center;
}

.modalInner {
  @apply bg-white max-w-95% flex flex-col relative;
}

.closeButton {
  fill: #222222;
  z-index: 1000;
  @apply absolute right-16px top-16px cursor-pointer;
}
